module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"goolgeAnalytics":{"trackingId":"UA-161759121-1","anonymize":true,"cookieName":"gdpr-google-analytics"},"environments":["production"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Raven Code Limited","short_name":"Raven Code","start_url":"/","background_color":"#f8f8ff","theme_color":"#313639","display":"minimal-ui","orientation":"portrait-primary","icon":"src/assets/images/raven-code-symbol-black.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
